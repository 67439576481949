/** @jsx jsx */
import { jsx, Flex, Grid } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React from "react"
import NavMenuDesktop from "../components/navMenuDesktop"
import NavMenuMobile from "../components/navMenuMobile"
import HomeNewsItem from "../components/homeNewsItem"
import Footer from "../components/footer"
import SEO from "../components/seo"

const NewsPage = ({ data }) => {
  return (
    <>
      <SEO title="Latest News" />
      <NavMenuMobile />
      <Grid
        gap={2}
        columns={[1, "1fr 2fr", "1fr 3fr"]}
        sx={{
          //bg: lighten("primary", 0.35), //0.35 on the red
          bg: "primaryBG",
        }}
      >
        <section>
          <NavMenuDesktop navPosition="left" />
        </section>
        <section
          sx={{
            minHeight: "100vh",
            marginRight: [3, 3, 4],
            ml: [3, 0],
            mt: ["70px", "initial"],
          }}
        >
          <h1
            sx={{
              color: "primary",
            }}
          >
            Latest News
          </h1>
          {data.news.edges.map(({ node }) => (
            <HomeNewsItem production={node} key={node.id} />
          ))}
        </section>
      </Grid>
      <Footer navPosition="left" />
    </>
  )
}

export default NewsPage

export const query = graphql`
  query {
    news: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "news" } } }
      sort: { fields: frontmatter___order, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            excerpt
            cropPosition
            thumb {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            title
            feat_img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date(formatString: "DD MMMM, YYYY")
            order
          }
          fields {
            slug
          }
          html
        }
      }
    }
  }
`
