/** @jsx jsx */
import { jsx, Flex, Box, Button } from "theme-ui"
import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import BackgroundImg from "gatsby-background-image"

const HomeNewsItem = ({ production }) => {
  return (
    <Flex
      sx={{
        //bg: 'white',
        mb: 4,
        px: 0,
        display: ["block", "flex"],
        "&:nth-child(odd)": {
          flexDirection: "row-reverse",
        },
      }}
    >
      <BackgroundImg
        fluid={production.frontmatter.thumb.childImageSharp.fluid}
        backgroundColor="#ccc"
        alt={production.frontmatter.name}
        sx={{
          width: ["100%", "40%", "30%"],
          height: ["150px", "auto"],
        }}
        style={{
          backgroundPositionY: production.frontmatter.cropPosition,
        }}
      />
      <Box
        sx={{
          width: ["100%", "70%"],
          bg: "white",
          px: 3,
          paddingBottom: 3,
        }}
      >
        <h3
          sx={{
            marginBottom: 0,
            mt: 0,
            pt: 3,
          }}
        >
          {production.frontmatter.name}
        </h3>
        <h5
          sx={{
            color: "mutedText",
            marginTop: 0,
            marginBottom: 2,
          }}
        >
          {production.frontmatter.date}
        </h5>
        <p>{production.frontmatter.excerpt}</p>
        <Link
          to={`/news/${production.fields.slug}`}
          sx={{
            bg: "primary",
            color: "white",
            textDecoration: "none",
            px: 2,
            py: 1,
            "&:hover, &:focus": {
              bg: "mutedText",
            },
          }}
        >
          Read more
        </Link>
      </Box>
    </Flex>
  )
}

export default HomeNewsItem
